<template>
  <div class="container-fluid">
    <TableWithPagination
      ref="manufacturers"
      :items="manufacturers"
      :fields="manufacturersFields"
      :totalRows="manufacturersTotal"
      @change-params="onManufacturersChangeParams"
      @row-selected="onManufacturersRowSelected"
    >
      <template v-slot:filters="{ filters, changeFilter }">
        <div class="row">
          <div class="col">
            <b-form-select v-model="filters.regionId" :options="regionsDD" @change="changeFilter('regionId', $event)">
              <template #first>
                <b-form-select-option :value="undefined">Регион</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col">
            <b-form-select v-model="filters.managerId" :options="managersDD" @change="changeFilter('managerId', $event)">
              <template #first>
                <b-form-select-option :value="undefined">Менеджер</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col">
            <b-form-input v-model="filters.search" placeholder="Поиск" type="search" @input="changeFilter('search', $event)"></b-form-input>
          </div>
        </div>
      </template>
      <template v-slot:filters-actions>
        <b-button v-if="!isAdmin && !isCurator" variant="success" @click="onAddManufacturer">Добавить производителя</b-button>
      </template>
      <template v-slot:row-actions v-if="isAdmin">
        <b-button class="mr-2 btn-danger" v-b-modal.modalDeleteManufacturer v-if="isAdmin">Удалить производителя</b-button>
        <b-button class="mr-2 btn-success" v-b-modal.modalChangeUser>Сменить пользователя</b-button>
        <b-button class="mr-2 btn-success" v-b-modal.modalChangeManager>Сменить менеджера</b-button>
        <b-button class="mr-2 btn-success" @click="onManufacturerShow">Просмотр</b-button>
        <b-button v-if="isAdmin" class="mr-2 btn-success" @click="onEditManufacturer" :disabled="isEditDisabled">Редактировать</b-button>
        <span v-if="isEditDisabled" class="text-danger">Данный производитель не подтвержден и не может быть отредактирован</span>
        <span class="ml-3 spinner" v-if="loading">
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </span>
        <div class="text-danger">{{ message }}</div>
      </template>
    </TableWithPagination>

    <RightSidebar
      form="ManufacturerForm"
      :form-data="manufacturerFormData"
      :mode="manufacturerFormMode"
      :show="manufacturerFormShow"
      @update:show="manufacturerFormShow = $event;manufacturerFormData = {}"
      @saved:form-data="onSavedManufacturerForm"
    ></RightSidebar>

    <b-modal id="modalDeleteManufacturer" title="Удалить производителя" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onDeleteManufacturer">
      Подтвердите удаление производителя
    </b-modal>

    <b-modal
      v-if="isAdmin"
      id="modalChangeUser"
      title="Сменить пользователя"
      size="lg"
      ok-title="Сменить"
      cancel-title="Отмена"
      centered
      @show="onShowUsersModal"
      @hidden="onHideUsersModal"
      :ok-disabled="!isUserSelected"
      @ok="onChangeUser"
    >
      <div class="mb-4 row">
        <div class="col-6">
          <b-form-input v-model="searchUserText" placeholder="Поиск" type="search" @input="onSearchUserChange"></b-form-input>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-table
            ref="selectableTable"
            sticky-header="calc(100vh - 420px)"
            hover
            selectable
            select-mode="single"
            :fields="modalUsersFields"
            :items="usersByRegion"
            :no-local-sorting="true"
            @row-selected="onUsersRowSelected"
          ></b-table>
          <div v-if="!usersByRegionTotal" class="text-center">Нет записей</div>
        </div>
      </div>
    </b-modal>

    <b-modal v-if="isAdmin" id="modalUserIsLocked" title="Пользователь заблокирован" size="lg" ok-title="ДА" cancel-title="Нет" centered @ok="onChangeLockedUser">
      <p>Данный пользователь заблокирован.</p>
      <p>Вы действительно хотите разблокировать пользователя и привязать его к партнеру?</p>
    </b-modal>

    <b-modal
      v-if="isAdmin"
      id="modalChangeManager"
      title="Сменить менеджера"
      ok-title="Сменить"
      cancel-title="Отмена"
      centered
      @show="onShowManagersModal"
      @hide="onHideManagersModal"
      :ok-disabled="managerSelectId === null"
      @ok="onChangeManager"
    >
      <b-form-select v-model="managerSelectId" :options="modmanagers">
        <template #first>
          <b-form-select-option :value="null">Менеджер</b-form-select-option>
        </template>
      </b-form-select>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapGetters } from "vuex";
import { DateFormat } from "@/helpers";
import { TableWithPagination, RightSidebar } from "@/components";

export default {
  name: "Manufacturers",
  components: {
    TableWithPagination,
    RightSidebar
  },
  data() {
    return {
      loading: false,
      message: null,

      filters: {
        regionId: null,
        managerId: null,
        search: "",
      },
      manufacturersFields: [
        { key: "addressId", label: "ID", sortable: true },
        { key: "inn", label: "ИНН", sortable: true },
        { key: "name", label: "Наименование", sortable: true },
        { key: "coordinates", label: "Координаты", sortable: true },
        { key: "address", label: "Фактический адрес", sortable: true },
        { key: "region", label: "Регион", sortable: true },
        { key: "manager", label: "Менеджер", sortable: true },
        { key: "statusView", label: "Статус", sortable: true },
        { key: "hasCertificate", label: "Сертификат", sortable: true, formatter: (item) => (item ? "Да" : "Нет") },
        { key: "certificateExpirationDate", label: "Действителен до", sortable: true, formatter: (item) => DateFormat.getDate(item) },
      ],
      selectedManufacturer: null,
      manufacturerFormData: {},
      manufacturerFormMode: "ADD",
      manufacturerFormShow: false,

      searchUserText: "",
      modalUsersFields: [
        { key: "firstName", label: "Имя", sortable: false },
        { key: "lastName", label: "Фамилия", sortable: false },
        { key: "email", label: "Email", sortable: false },
      ],
      modalUsersRowSelect: null,

      modmanagers: null,
      managerSelectId: null
    };
  },
  methods: {
    ...mapActions("regions", ["fetchRegions"]),
    ...mapActions("manufacturers", [
      "fetchManagers",
      "fetchManufacturers",
      "addManufacturer",
      "editManufacturer",
      "fetchManagersByRegion",
      "fetchUsersByManufacturerRegion",
      "changeActiveManufacturerUser",
      "changeManager",
      "deleteManufacturer",
    ]),

    async onDeleteManufacturer() {
      this.loading = true;
      this.deleteManufacturer(this.selectedManufacturer.id).then(() => {
        this.loading = false;
        this.onSavedManufacturerForm();
      });
    },

    onManufacturersChangeParams(params) {
      this.fetchManufacturers(params);
    },
    onManufacturersRowSelected(manufacturer) {
      this.selectedManufacturer = manufacturer;
      if (!this.isAdmin) this._showSelectedManufacturer();
    },
    onManufacturerShow() {
      this._showSelectedManufacturer();
    },
    _showSelectedManufacturer() {
      if (this.selectedManufacturer) this.$router.push(`/manufacturers/${this.selectedManufacturer.id}`);
    },

    onAddManufacturer() {
      this.manufacturerFormData = {}
      this.manufacturerFormMode = "ADD"
      this.manufacturerFormShow = true
    },
    onEditManufacturer() {
      this.manufacturerFormMode = "EDIT"
      this.manufacturerFormData = { ...this.selectedManufacturer, ... {
        manufacturerId: this.selectedManufacturer.id
      }};
      this.manufacturerFormShow = true
    },
    onSavedManufacturerForm() {
      this.$refs.manufacturers.reload();
    },

    onShowUsersModal() {
      this.fetchUsersByManufacturerRegion({ id: this.selectedManufacturer.id, search: null });
    },
    onHideUsersModal() {
      // TODO:
    },
    onSearchUserChange() {
      this.fetchUsersByManufacturerRegion({ id: this.selectedManufacturer.id, serach: this.searchUserText });
    },
    onUsersRowSelected(items) {
      this.modalUsersRowSelect = (items || [])[0];
      console.log(this.modalUsersRowSelect);
    },
    onChangeUser() {
      if (this.modalUsersRowSelect.isLocked) {
        this.$bvModal.show("modalUserIsLocked");
      } else {
        this._changeActiveManufacturerUser();
      }
    },
    onChangeLockedUser() {
      this._changeActiveManufacturerUser();
    },
    _changeActiveManufacturerUser() {
      this.changeActiveManufacturerUser({ id: this.selectedManufacturer.id, userId: this.modalUsersRowSelect.id });
    },

    onShowManagersModal() {
      this.fetchManagersByRegion(this.selectedManufacturer.regionId).then(() => {
        this.modmanagers = this.managersByRegion
          .filter((item) => item.id != this.selectedManufacturer.managerId)
          .map((item) => {
            let name = `${item.firstName} ${item.lastName}`;
            return { value: item.id, text: name };
          });
      });
    },
    onHideManagersModal() {
      // TODO:
    },
    onChangeManager() {
      this.changeManager({ id: this.managerSelectId, partnerId: this.selectedManufacturer.id }).then(() => {
        this.$refs.manufacturers.reload();
      });
    },


  },
  computed: {
    ...mapGetters(["userRole", "isAdmin", "isManager", "isLeader", "isCurator"]),
    ...mapGetters("regions", ["regionsDD", "regionsDD"]),
    ...mapGetters("manufacturers", ["managersDD"]),
    ...mapState("manufacturers", {
      manufacturers: (state) => state.manufacturers,
      manufacturersTotal: (state) => state.manufacturersTotal,
      usersByRegion: (state) => state.usersByRegion,
      usersByRegionTotal: (state) => (state.usersByRegion || []).length,
      managers: (state) => state.managers,
      managersByRegion: (state) => state.managersByRegion,
    }),

    isEditDisabled() {
      return this.selectedManufacturer.status !== 2; // Подтвержден
    },

    isUserSelected() {
      return !!this.modalUsersRowSelect;
    },
  },
  created() {
    this.fetchManagers();
    this.fetchRegions();
  },
};
</script>

<style lang="scss">
.b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -1px !important;
}
.spinner {
  position: relative;
  top: 9px;
}
</style>
